@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap');

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

body {
  background-color: black;
}

.hover\:filter-grayscale-80:hover {
  filter: grayscale(80%);
}